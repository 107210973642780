import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const modal = document.querySelector("#modal-tribute_onboarding");
    if (modal && !localStorage.getItem("tribute_onboarding_modal_closed")) {
      window.addEventListener("load", () => {
        window.HSOverlay.open("#modal-tribute_onboarding");
      });
    }
  }

  close() {
    window.HSOverlay.close("#modal-tribute_onboarding");
    localStorage.setItem("tribute_onboarding_modal_closed", "true");
  }
}
