import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.addTrackingOnClick(this.setTrackingData());
    this.getDashboardPage();
  }

  addTrackingOnClick(trackingData) {
    const button = this.element.querySelector('div[name="button"]');

    if (!button) return;

    button.addEventListener("click", (event) => {
      window.sendSegmentAnalytics("Nok", this.getDashboardPage(), "task CTA clicked", {
        ...trackingData,
      });
    });
  }

  getDashboardPage() {
    const path = window.location.pathname.split("/")[2];
    const page = path.split("_").join(" ");

    return "dashboard" + " " + page;
  }

  setTrackingData() {
    const task = this.element;
    const taskTitle = task.dataset.taskTitle;
    const taskButtonLabel = task.dataset.taskButtonLabel;
    const taskButtonLink = task.dataset.taskButtonLink;
    const taskUrgent = task.dataset.taskUrgent;

    const trackingData = {
      taskTitle,
      taskButtonLabel,
      taskButtonLink,
      taskUrgent,
    };

    return trackingData;
  }
}
