export default() => {
  const copyButton = document.getElementById("copyText");
  if (copyButton) {
    copyButton.addEventListener("click", function(e) {
      var copyText = document.getElementById("copyText");
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.getAttribute("data-text"));
      copyText.innerHTML = "Copied!";
      setTimeout(function() {
        copyText.innerHTML = "Copy";
      }, 3000);
    });
  }
}