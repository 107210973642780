import { Controller } from "@hotwired/stimulus";
import handleComplete from "./form_events/handleComplete";
import handlePartialSend from "./form_events/handlePartialSend";
import handleValueChanged from "./form_events/handleValueChanged";
import addReviewStyling from "./form_functions/addReviewStyling";
import handleAfterRenderQuestion from "./form_functions/handleAfterRenderQuestion";

export default class extends Controller {
  connect() {
    this._renderForm(this.data.get("form-data"), this.data.get("response-url"));

    window.onload = () => {
      this._moveElements();
    };

    if (this.data.get("admin") === "true") {
      window.addEventListener("beforeunload", this.beforeUnloadHandler);
    }
  }

  beforeUnloadHandler = (event) => {
    event.preventDefault();
    event.returnValue = true;
  };

  _renderForm(surveyJSON, url) {
    Survey.StylesManager.applyTheme("defaultV2");
    window.survey = new Survey.Model(surveyJSON);

    // this one should be from response data rather than local storage
    var responseData = this.data.get("response-data");
    if (responseData && responseData !== "null") {
      var data = JSON.parse(responseData);
      survey.data = data;
      if (data.pageNo) {
        survey.currentPageNo = data.pageNo;
      }
    }

    this._renderMarkdown();

    $("#surveyContainer").Survey({
      model: survey,
      sendResultOnPageNext: true,
      onPartialSend: handlePartialSend,
      onValueChanged: handleValueChanged,
      onComplete: handleComplete,
      onAfterRenderQuestion: handleAfterRenderQuestion,
    });
  }

  _moveElements() {
    // Move header within the survey.js questions element.
    const header = document.querySelector(".bare-form-header");
    const headerLocation = document.querySelector(".sd-body--responsive");
    if (headerLocation && headerLocation.firstChild)
      headerLocation.insertBefore(header, headerLocation.firstChild);

    // Move Bare logo within the sidebar element.
    const logo = document.querySelector(".bare-logo");
    const mobileLogo = document.querySelector(".mobile-navigation > .bare-logo");
    if (logo) logo.style.display = "block";
    if (mobileLogo) mobileLogo.style.display = "block";
    const logoLocation = document.querySelector(".sd-progress-buttons__container");

    if (logoLocation && logoLocation.firstChild)
      logoLocation.insertBefore(logo, logoLocation.firstChild);
  }

  _renderMarkdown() {
    //Create showdown mardown converter
    var converter = new showdown.Converter({ openLinksInNewWindow: true });
    window.survey.onTextMarkdown.add(function (survey, options) {
      //convert the mardown text to html
      var str = converter.makeHtml(options.text);
      //remove root paragraphs <p></p>
      str = str.substring(3);
      str = str.substring(0, str.length - 4);
      //set html
      options.html = str;
    });
  }
}