import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "image"];

  removeImage(event) {
    event.preventDefault();

    this.imageTarget.classList.add("hidden");
    this.buttonTarget.classList.remove("hidden");

    const formData = new FormData();
    formData.append(
      this.element.dataset.formObjectName + "[" + this.element.dataset.fileFieldset + "]",
      ""
    );

    const options = {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")?.content,
      },
    };

    fetch(this.element.dataset.deleteEndpoint, options)
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(() => {})
      .catch((error) => {
        console.error("Error:", error);
      });
  }
}
