import { Controller } from "@hotwired/stimulus";

const MOBILE_WIDTH = 768;

export default class extends Controller {
  static targets = ["button", "text", "icon", "stepper", "stepperWrapper", "title"];

  connect() {
    this.initializeStepper();
    this.setupEventListeners();
    this.adjustForMobile();
    this.adjustUIComponents();
  }

  setupEventListeners() {
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("click", () => this.toggleStepperWrapper());
    }

    window.addEventListener("resize", () => this.adjustUIComponents());

    this.element.querySelectorAll("#scroll-link").forEach((link) => {
      link.addEventListener("click", (event) => this.handleScrollLinkClick(event, link));
    });
  }

  adjustUIComponents() {
    this.setTitleHeight();
    this.setDetails();
  }

  adjustForMobile() {
    if (window.innerWidth < MOBILE_WIDTH) {
      this.toggleStepperWrapper();
    }
  }

  handleScrollLinkClick(event, link) {
    event.preventDefault();
    const targetId = link.getAttribute("data-target");
    const targetUrl = link.getAttribute("data-url");
    const targetLocation = targetUrl + "#" + targetId;

    if (window.location.pathname !== targetUrl) {
      window.location = targetLocation;
    } else {
      this.scrollToElement(targetId);
    }
  }

  toggleStepperWrapper() {
    this.stepperWrapperTarget.classList.toggle("opened");
    if (this.hasIconTarget) {
      this.iconTarget.classList.toggle("rotate-180");
    }
    this.setDetails();
  }

  setDetails() {
    if (this.stepperWrapperTarget.classList.contains("opened")) {
      this.stepperWrapperTarget.style.maxHeight = this.stepperWrapperTarget.scrollHeight + "px";
      if (this.hasTextTarget) {
        this.textTarget.textContent = "View Less Details";
      }
    } else {
      this.stepperWrapperTarget.style.maxHeight = 0;
      if (this.hasTextTarget) {
        this.textTarget.textContent = "View More Details";
      }
    }
  }

  setTitleHeight() {
    this.titleTargets.forEach((title) => {
      title.style.height = "auto";
    });

    let maxHeight = Math.max(...this.titleTargets.map((title) => title.offsetHeight));

    this.titleTargets.forEach((title) => {
      title.style.height = `${maxHeight}px`;
    });
  }

  initializeStepper() {
    const currentStep = this.stepperTarget.getAttribute("step");
    const interval = 2000;
    const maxAttempts = 20;

    let attempts = 0;

    const checkStepperInstance = () => {
      if (!window.$hsStepperCollection) {
        window.$hsStepperCollection = [];
      }

      const stepperInstance = window.HSStepper.getInstance("#stepper");

      if (stepperInstance) {
        for (let i = 0; i <= currentStep; i++) {
          stepperInstance.setCompleteItem(i);
        }
      } else if (attempts < maxAttempts) {
        attempts++;
        setTimeout(checkStepperInstance, interval);
      } else {
        console.error("No stepper after 20 attempts.");
      }
    };

    // Start polling
    checkStepperInstance();
  }

  scrollToElement(hash) {
    const targetElement = document.getElementById(hash);
    if (targetElement) {
      const collapseButton = targetElement.querySelector(".bfg-collapse");
      if (!collapseButton.classList.contains("active")) {
        collapseButton.click();
      }
      setTimeout(() => targetElement.scrollIntoView({ behavior: "smooth", block: "start"}), 300);
    }
  }
}
