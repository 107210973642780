import config from "@app/config";

const stripe = Stripe(config.stripePublicKey);
const clientSecret = document.querySelector("#payment-intent-info").dataset.clientSecret;
let card, auBankAccount;

// Fetches a payment intent and captures the client secret
async function initialize() {
  const style = {
    base: {
      fontSize: "18px",
      fontFamily: "Open Sans, sans-serif",
      fontWeight: 300,
      "::placeholder": {
        backgroundColor: "#fff",
      },
    },
  };

  const options = {
    style: style,
    disabled: false,
    hideIcon: false,
    iconStyle: "default", // or "solid"
  };
  const elements = stripe.elements();
  if (document.getElementById("card-payment-element")) {
    card = elements.create("card", options);
    card.mount("#card-payment-element");
  } else if (document.getElementById("au-bank-account-element")) {
    auBankAccount = elements.create("auBankAccount", options);
    auBankAccount.mount("#au-bank-account-element");
  }
}

async function handleSubmit(e) {
  e.preventDefault();
  document.querySelector("button[type=submit]").disabled = true;
  document.querySelector(".update-payment-successful-message").style.display = "flex";
  document.querySelector(".update-payment-method-container").style.display = "none";

  const nameInput = document.querySelector("#name");
  const emailInput = document.querySelector("#email");
  const returnUrl = document.querySelector("#update-payment-form").getAttribute("data-url");
  // Confirm the card payment given the clientSecret
  // from the payment intent that was just created on
  // the server.
  const { error } = await (document.getElementById("au-bank-account-element")
    ? stripe.confirmAuBecsDebitSetup(clientSecret, {
        payment_method: {
          au_becs_debit: auBankAccount,
          billing_details: {
            name: nameInput.value,
            email: emailInput.value,
          },
        },
        return_url: returnUrl,
      })
    : stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: card,
          billing_details: {
            name: nameInput.value,
          },
        },
        return_url: returnUrl,
      }));

  document.querySelector("button[type=submit]").disabled = false;
  if (error) {
    document.querySelector(".update-payment-successful-message").style.display = "none";
    document.querySelector(".update-payment-method-container").style.display = "block";
    showMessage(error.message);
  } else {
    setTimeout(function () {
      document.querySelector(".waitingdiv").style.display = "flex";
    }, 1500);
    setTimeout(function () {
      window.location.href = returnUrl;
    }, 10000);
  }
}

// ------- UI helpers -------

function showMessage(messageText) {
  const messageContainer = document.querySelector("#payment-message");

  messageContainer.classList.remove("hidden");
  messageContainer.textContent = messageText;

  setTimeout(function () {
    messageContainer.classList.add("hidden");
    messageContainer.textContent = "";
  }, 4000);
}

export default () => {
  initialize();

  document.querySelector("#update-payment-form").addEventListener("submit", handleSubmit);

  document
    .querySelector(".tabcontent.active > .collapse-container:nth-child(2) > .bfg-collapse")
    .click();
};
