import FileUploadController from "./file_upload";
import { sendError } from "./file_validation";

export default class extends FileUploadController {
  static targets = [
    ...FileUploadController.targets,
    "button",
    "loading",
    "uploadLabel",
    "uploadSubLabel",
  ];

  connect() {
    this.isPolling = false;
    this.allFilesPosted = false;
    super.connect();
  }

  addEventListeners() {
    super.addEventListeners();

    const closeButton = document.getElementById(
      `${this.submitTarget.dataset.modalTitle}-close-button`
    );

    if (closeButton) {
      closeButton.addEventListener("click", (event) => {
        if (this.allFilesPosted) {
          event.preventDefault();
          window.location.reload();
        }
      });
    }

    this.submitTarget.addEventListener("submit", (event) => {
      if (!this.allFilesPosted) {
        event.preventDefault();
        this.postFiles();
      }
    });
  }

  startPolling() {
    const MAX_RETRIES = 15;
    const INTERVAL = 4000;
    let count = 0;

    if (!this.isPolling) {
      this.timer = setInterval(() => {
        if (count < MAX_RETRIES) {
          this.refresh(this.submitTarget.action);
          count++;
        } else {
          console.error("Error: Polling timed out.");
          clearInterval(this.timer);
          this.isPolling = false;
        }
      }, INTERVAL);
      this.isPolling = true;
    }
  }

  async postFiles() {
    this.toggleButtonLoading();

    const formData = new FormData();
    const { fieldName, formObjectName, multipleFiles } = this.submitTarget.dataset;

    Array.from(this.fileTarget.files).forEach((file) => {
      const fieldKey =
        multipleFiles === "false"
          ? `${formObjectName}[${fieldName}]`
          : `${formObjectName}[${fieldName}][]`;
      formData.append(fieldKey, file);
    });

    const options = {
      method: "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")?.content,
      },
    };

    try {
      const response = await fetch(this.submitTarget.action, options);
      if (!response.ok) throw new Error(response.statusText);
      this.allFilesPosted = true;
      this.startPolling();
    } catch (error) {
      console.error("Error:", error);
      sendError(`File upload failed: ${error.message}`, this.element);
      this.toggleButtonLoading();
    }
  }

  async refresh() {
    const { pollingParam, pollingUrl } = this.submitTarget.dataset;
    const url = new URL(pollingUrl, window.location.origin);
    url.searchParams.append("document_type", pollingParam);

    try {
      const response = await fetch(url, { method: "GET" });
      if (!response.ok) throw new Error(response.status);

      const data = await response.json();
      if (data.status === "completed") {
        this.stopPolling();
        this.handleSuccess();
      }
    } catch (error) {
      console.error("Error:", error);
      sendError(`Polling error: ${error.message}`, this.element);
      this.toggleButtonLoading();
      this.buttonTarget.disabled = true;
    }
  }

  stopPolling() {
    if (this.isPolling) {
      clearInterval(this.timer);
      this.isPolling = false;
    }
  }

  handleSuccess() {
    this.wrapperTarget.classList.add("success");
    this.uploadLabelTarget.textContent = "File(s) submitted successfully";
    this.uploadSubLabelTarget.textContent =
      "Thank you for your submission. You may now close this window.";
    this.toggleButtonLoading();
    this.emptyFileList();
  }

  toggleButtonLoading() {
    const spinnerElement = this.buttonTarget.querySelector("[name='spinner']");
    const buttonTextElement = this.buttonTarget.querySelector("[name='button-text']");

    this.buttonTarget.disabled = !this.buttonTarget.disabled;
    spinnerElement.classList.toggle("hidden");
    buttonTextElement.textContent =
      buttonTextElement.textContent === "Uploading" ? "Upload" : "Uploading";
  }

  emptyFileList() {
    super.emptyFileList();
    this.buttonTarget.disabled = true;
  }
}
