import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["canvas", "placeholder"];

  connect() {
    if (this.isVisible(this.canvasTarget)) {
      this.initializeCanvas();
    }
  }

  show() {
    this.initializeCanvas();
  }

  initializeCanvas(brand) {
    this.sigPad = new SignaturePad(this.canvasTarget, {
      minWidth: 1,
      maxWidth: 3,
      penColor: brand === "bare" ? "#035e61" : "#003164",
    });
    this.canvasTarget.addEventListener("pointerdown", this.hidePlaceholder.bind(this));

    const rect = this.canvasTarget.getBoundingClientRect();
    this.canvasTarget.width = rect.width * window.devicePixelRatio;
    this.canvasTarget.height = rect.height * window.devicePixelRatio;
    this.canvasTarget.style.width = rect.width + "px";
    this.canvasTarget.style.height = rect.height + "px";

    const context = this.canvasTarget.getContext("2d");
    context.scale(window.devicePixelRatio, window.devicePixelRatio);
  }

  clearSignature(event) {
    event.preventDefault();
    this.sigPad.clear();
  }

  hidePlaceholder() {
    this.placeholderTarget.style.display = "none";
  }

  getSignatureData() {
    return new Promise((resolve, reject) => {
      if (!this.sigPad.isEmpty()) {
        this.canvasTarget.toBlob((blob) => {
          resolve(blob);
        }, "image/png");
      } else {
        reject(new Error("Signature pad is empty"));
      }
    });
  }

  isVisible(element) {
    return (
      !!element &&
      !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
    );
  }
}
