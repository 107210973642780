import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["name", "link", "error", "button"];

  connect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("click", this.sendSegmentEvent);
    }
  }

  toggleRequired(event) {
    event.preventDefault();

    var charityRequired = this.isCharityRequired();
    if (charityRequired == false) {
      this.errorTarget.classList.add("hidden");
    }

    this.nameTarget.required = charityRequired;
    this.linkTarget.required = charityRequired;

    var linkValue = this.linkTarget.value;
    if (linkValue != "" && !(linkValue.startsWith("https://") || linkValue.startsWith("http://"))) {
      this.linkTarget.value = "https://" + linkValue;
    }
  }

  sendSegmentEvent(event) {
    window.sendSegmentAnalytics("Nok", "Tributes Page", "Charity Link Clicked By Visitor", {});
  }

  checkCharityFields() {
    if (this.isCharityRequired() == true) {
      this.errorTarget.classList.toggle("hidden");
    }
  }

  isCharityRequired() {
    if (
      (this.nameTarget.value == "" && this.linkTarget.value == "") ||
      (this.nameTarget.value != "" && this.linkTarget.value != "")
    ) {
      return false;
    } else {
      return true;
    }
  }
}
