import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["options", "dropdownField", "fieldName", "input"];

  connect() {
    if (this.hasInputTarget && this.inputTarget.type === "checkbox") {
      this._getCheckedUnchecked();
    } else if (this.hasInputTarget && this.inputTarget.type === "radio") {
      this._getSelectedRadio();
    }

    this._getSelectedChanged();

    document.addEventListener("DOMContentLoaded", async (event) => {
      this.closeDropdown = this.closeDropdown.bind(this);
      document.addEventListener("click", this.closeDropdown);
    });
  }

  closeDropdown(event) {
    if (!this.element.contains(event.target)) {
      this.optionsTarget.removeAttribute("collapsed");
    }
  }

  showOptions() {
    if (this.optionsTarget.getAttribute("collapsed") === "") {
      this.optionsTarget.removeAttribute("collapsed");
    } else {
      this.optionsTarget.setAttribute("collapsed", "");
    }
  }

  _getCheckedUnchecked() {
    const checkboxes = this.optionsTarget.querySelectorAll(".checkbox-input");
    let selectedOptions = [];

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener("change", (event) => {
        const option = event.target.id;
        if (event.target.checked) {
          selectedOptions.push(option);
        } else {
          selectedOptions.splice(selectedOptions.indexOf(option), 1);
        }

        this._setFieldName(selectedOptions.length, selectedOptions[0]);

        // Dispatch event with selected options that can be listened to by other controllers.
        const selectedOptionsEvent = new CustomEvent("selectedOptionsChanged", {
          detail: {
            allSelectedOptions: selectedOptions,
            currentOption: { option: option, checked: event.target.checked },
            id: this.element.id,
          },
        });
        document.dispatchEvent(selectedOptionsEvent);
      });
    });
  }

  _getSelectedRadio() {
    const radios = this.optionsTarget.querySelectorAll(".option-radio");
    let selectedOption;

    radios.forEach((radio) => {
      radio.addEventListener("change", (event) => {
        const option = event.target.nextElementSibling.innerText;
        if (event.target.checked) {
          selectedOption = option;
        }

        this._setFieldName(selectedOption ? 1 : 0, selectedOption);

        // Dispatch event with selected options that can be listened to by other controllers.
        const selectedOptionsEvent = new CustomEvent("selectedOptionsChanged", {
          detail: {
            allSelectedOptions: selectedOption ? [selectedOption] : [],
            currentOption: { option: option, checked: event.target.checked },
            id: this.element.id,
          },
        });
        document.dispatchEvent(selectedOptionsEvent);
        this.showOptions();
      });
    });
  }

  _getSelectedChanged() {
    document.addEventListener("removeTagClicked", ({ detail: { tag, selectedTags } }) => {
      document.getElementById(tag).checked = false;
      this._setFieldName(selectedTags.length, selectedTags[0]);
    });
  }

  _setFieldName(amount, option) {
    if (amount === 0) {
      this.dropdownFieldTarget.value = "All";
    } else if (amount === 1) {
      this.dropdownFieldTarget.value = option;
    } else {
      this.dropdownFieldTarget.value = amount + " selected";
    }
  }
}
